// @flow
import type { PreviewPathResolver } from '../../src/plugins/prismic/types';
import type { PreviewPathResolvers, PreviewPathResolverByType } from './types';

/* GatsbyStarterConfig: preview paths for Prismic nodes */
const previewPathResolvers: PreviewPathResolvers = {
  '404_page': () => '/404',
  contact: undefined,
  cookies: undefined,
  home_page: () => '/',
  privacy_policy: undefined,
  organization: undefined,
  author: undefined,
  site_info: undefined,
};

const defaultPreviewPathResolver: PreviewPathResolverByType = ({
  previewPath,
  doc,
}) => `${previewPath}/${doc.type}`;

const previewPathResolver: PreviewPathResolver = ({
  locale,
  previewPath,
}) => doc => {
  const resolvePath: PreviewPathResolverByType =
    previewPathResolvers[doc.type] || defaultPreviewPathResolver;

  return resolvePath({ locale, previewPath, doc });
};

module.exports = previewPathResolver;
