// @flow
import * as React from 'react';
import { navigate } from 'gatsby';
import type { Location } from '@reach/router';
import withStyles, {
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import ResPadding from '~plugins/material-ui/components/ResPadding';
import AppHelmet from '~plugins/helmet/components/AppHelmet';
import usePreviewPage from '~plugins/prismic/hooks/usePreviewPage';
import type { Query } from '~schema';

export type ClassKey = 'root';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  location: Location,
};

const styles = (unusedTheme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const Preview = ({ location, classes }: Props) => {
  const { previewData, path } = usePreviewPage<Query>(location);

  React.useEffect(() => {
    if (previewData && typeof path === 'string') {
      navigate(path, {
        state: { previewData: JSON.stringify(previewData) },
      });
    }
  }, [previewData, path]);

  return (
    <Container
      component={ResPadding}
      vertical
      className={classes.root}
    >
      <AppHelmet title="Preview" />
      <CircularProgress />
    </Container>
  );
};

export default withStyles<ClassKey, *, Props>(styles)(Preview);
