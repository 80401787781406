// @flow
import * as React from 'react';
import type { Location } from '@reach/router';
import useTheme from '@material-ui/core/styles/useTheme';
import type { Theme } from '@material-ui/core/styles';
import { usePrismicPreview } from 'gatsby-source-prismic';

import config$linkResolver from '../../../../config/prismic/linkResolver';
import config$htmlSerializer from '../../../../config/prismic/htmlSerializer';
import config$previewPathResolver from '../../../../config/prismic/previewPathResolver';

import type { Config } from '../types';
import createPreviewPathResolver from '../helpers/createPreviewPathResolver';
import wrapConfig from '../helpers/wrapConfig';

// eslint-disable-next-line prefer-destructuring
const window: ?{
  ___PRISMIC___: ?{
    pluginOptions: Config,
    schemasDigest: string,
  },
} = global.window;

export default function usePreviewPage<T>(location: Location) {
  const { pluginOptions } = (window && window.___PRISMIC___) || {};

  const { linkResolver, htmlSerializer, previewPathResolver } = wrapConfig({
    accessToken: '',
    repositoryName: '',
    schemas: {},
    pages: [],
    linkResolver: config$linkResolver,
    htmlSerializer: config$htmlSerializer,
    previewPathResolver: config$previewPathResolver,
    theme: useTheme<Theme>(),
  });

  return usePrismicPreview<T>(location, {
    linkResolver,
    htmlSerializer,
    pathResolver: createPreviewPathResolver({
      previewPathResolver,
      previewPath: pluginOptions?.previewPath,
    }),
  });
}
