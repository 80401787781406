// @flow
import type { HtmlSerializer } from '../types';

const React = require('react');
const Typography = require('@material-ui/core/Typography').default;
const castArray = require('lodash/castArray');

const typographyVariantByType = {
  paragraph: undefined,
  heading1: 'h1',
  heading2: 'h2',
  heading3: 'h3',
  heading4: 'h4',
  heading5: 'h5',
  heading6: 'h6',
};

// eslint-disable-next-line react/display-name
const htmlSerializer: HtmlSerializer = ({ linkResolver, ...opts }) => (
  unusedType,
  element,
  unusedContent,
  children,
  key,
) => {
  if (!element || !element.type) {
    return null;
  }

  switch (element.type) {
    case 'hyperlink':
      return React.createElement(
        'a',
        {
          ...(element.data.target
            ? { target: element.data.target, rel: 'noopener noreferrer' }
            : {}),
          key,
          href: element.data.url || linkResolver({ ...opts })(element.data),
          'data-link-type': element.data.link_type,
        },
        children,
      );
    case 'paragraph':
    case 'heading1':
    case 'heading2':
    case 'heading3':
    case 'heading4':
    case 'heading5':
    case 'heading6':
      return React.createElement(Typography, {
        key,
        variant: typographyVariantByType[element.type],
        dangerouslySetInnerHTML: children
          ? { __html: castArray(children).join('') }
          : undefined,
      });
    case 'list-item':
      return React.createElement(Typography, {
        key,
        component: 'li',
        variant: 'body1',
        dangerouslySetInnerHTML: children
          ? { __html: castArray(children).join('') }
          : undefined,
      });
    default:
      // Return null to stick with the default behavior for all other elements
      return null;
  }
};

module.exports = htmlSerializer;
