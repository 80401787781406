// @flow
import find from 'lodash/find';
import { oneLine } from 'common-tags';

import { i18n } from '../../i18n';
import type { Doc, Config } from '../types';

export default function createPreviewPathResolver({
  previewPath,
  previewPathResolver,
}: {
  previewPath: $ElementType<Config, 'previewPath'>,
  previewPathResolver: $ElementType<Config, 'previewPathResolver'>,
}) {
  return (doc: Doc) => {
    const locale = find(i18n.getLocales(), { prismicLocale: doc.lang });

    const localizePath = (path: string) =>
      locale ? i18n.localizePath(locale, path) : path;

    if (!previewPath || !previewPathResolver) {
      throw new Error(oneLine`
        could not resolve preview path due to missing configuration. Make
        sure that both 'previewPath' and 'previewPathResolver' are
        provided in Prismic configuration.
      `);
    }

    return localizePath(
      previewPathResolver({ locale, previewPath })({
        ...doc,
        slug: doc.slugs[0],
      }),
    );
  };
}
